<template>
  <div class="slot-box" :class="classes">
    <slot>min, Width: {{ width }}</slot>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  box: HTMLElement,
  minWidth: number,
  maxWidth: number,
  classes?: string
}>();

// single ref
let width = ref(0);

const resizeObserver = new ResizeObserver(() => {
  width.value = props.box.offsetWidth;
});

onMounted(() => {
  nextTick().then(() => {
    try {
      resizeObserver.observe(props.box);
    } catch {
      watch(props.box, () => {
        width.value = props.box.offsetWidth;
        resizeObserver.observe(props.box);
      });
    }
  });
});

const display = computed(() => {
  if (width.value > props.minWidth && width.value < props.maxWidth) {
    return 'block';
  }
  return 'none';
});

onUnmounted(() => clearInterval(resizeObserver.disconnect()));
</script>

<style scoped lang="scss">
.slot-box {
  display: v-bind(display);
}
</style>
