import { useUserStore } from '~/store/UserModule';
import { defineStore } from 'pinia';

const runtimeConfig = useRuntimeConfig();

export const useChannelStore = defineStore('selectedChannel', () => {
    let userModule = useUserStore();
    const channelId: Ref<String> = ref('');
    const channelInfo: Ref<Object> = ref({});
    const requestChannelInfoLink = '/place/telegram/get'; // ?id=000000

    function requestChannelInfo() {
        if (!channelId.value || (channelId.value === '')) return;

        userModule.requestAuthKey();
        console.log(userModule.authorized);
    
        useFetch(requestChannelInfoLink, {
            baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
            method: 'GET',
            query: { id: channelId.value },
            onRequest({ request, options }) {
                // Set the request headers
                options.headers = options.headers || {}
                options.headers['x-api-key'] = userModule.authorized;
            },
            onRequestError({ request, options, error }) {
                // Handle the request errors
            },
            onResponse({ request, response, options }) {
                // Process the response data
                // TODO: remove console.log
                console.log(response);
                // TODO: remove response example
                window.response = response;
                channelInfo.value = response._data;
            },
            onResponseError({ request, response, options }) {
                // Handle the response errors
            }
        })
    } //requestChannelInfo

    return {
        channelId,
        channelInfo,
        requestChannelInfo,
        requestChannelInfoLink
    }
})